body {
  background-color: #efc050;
  margin: 0;
  padding: 0;
  font-family: 'Gloria Hallelujah', cursive;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.welcome-text {
  font-size: 6em; /* Increase text size */
  line-height: 1.2;
  color: #000000;
  text-align: center;
  margin: 0; /* Remove default margin */
}

.footer-text {
  font-size: 2em;
  color: #000000;
  text-align: center;
  margin-top: 20px; /* Add some margin to separate text and image */
}

.logo {
  width: 150px; /* Increase image size */
  height: auto; /* Maintain aspect ratio */
  margin-top: 20px; /* Adjust as needed */
}

a {
  color: #000000;
  text-decoration: none; /* Remove underlines from links */
}
